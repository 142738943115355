<template>
  <div>
    <v-menu :close-on-content-click="false" right offset-x>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon-table-facture"
          v-on="on"
          v-bind="attrs"
          @click.prevent="openModal"
        >
          <font-awesome-icon icon="info-circle" class="" />
        </div>
      </template>
      <v-card>
        <v-card-text class="body-card center-text">
          <v-data-table
            :headers="computedFields"
            :items="factureReglement"
            hide-default-footer
            hide-action
            disable-filtering
            disable-sort
            disable-pagination
            class="table-dialog"
            item-class="text-center"
            :items-per-page="factureReglement.length"
          >
            <template v-slot:[`item.payment_date`]="{ item }">
              <span class="text-center">
                {{ formateDateGetters(item.payment_date) }}
              </span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span class="text-center">{{ item.amount }}</span>
            </template>
            <template v-slot:[`item.payment_type`]="{ item }">
              <span class="text-center">{{ item.payment_type }}</span>
            </template>
            <template v-slot:[`item.update`]="{ item }">
              <td>
                <update-reglement-modal
                  :item="item"
                  :dataInterface="dataInterface"
                  @openModal="triggersPop = 'click'"
                  @closeModal="triggersPop = 'hover'"
                />
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    factureReglement: { required: true },
    permissionModifier: { required: false },
    dataInterface: { required: false }
  },
  components: {
    updateReglementModal: () =>
      import('@/views/component/reglement/updateReglementModal.vue')
  },
  data() {
    return {
      menu: false,
      error: false,
      triggersPop: 'hover'
    };
  },
  computed: {
    ...mapGetters(['checkPermission', 'formateDateGetters']),
    computedFields() {
      let fields = [
        {
          text: '#',
          align: 'start',
          value: 'payment_id',
          show: true
        },
        { text: 'Montant reglé', value: 'amount', show: true },
        { text: 'Date Paiement', value: 'payment_date', show: true },
        { text: 'Type Paiement', value: 'payment_type', show: true },
        { text: '', value: 'update', show: this.permissionModifier }
      ];
      return fields.filter(item => item.show == true);
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['']),
    preventClose(event) {
      // Empêche la fermeture du dialogue si l'arrière-plan est cliqué
      event.stopPropagation();
    },
    openModal() {
      this.menu = true;
    },
    hideModal() {
      this.menu = false;
      this.error = false;
    },
    clickButton(id) {
      this.exportPdfFactureSci({
        id: id
      });
    }
  }
};
</script>
